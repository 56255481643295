// Our main CSS
import '../css/app.css'
import marquee from 'vanilla-marquee'
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

window.addEventListener('DOMContentLoaded', () => {
  const swipers = document.querySelectorAll('.logo-swiper')

  swipers.forEach((swiper) => {
    new Swiper(swiper, {
      modules: [Autoplay],
      spaceBetween: 60,
      loop: true,
      freeMode: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false
      },
      speed: 5000,
      slidesPerView: 1.4,
      breakpoints: {
        440: {
          slidesPerView: '1.6'
        },
        640: {
          slidesPerView: '2.5'
        },
        768: {
          slidesPerView: '3.5'
        },
        1024: {
          slidesPerView: '4.5'
        },
        1280: {
          slidesPerView: '5.5'
        },
        1440: {
          slidesPerView: '6.5'
        }
      }
    })
  })
})

window.addEventListener('DOMContentLoaded', () => {
  new Swiper('.fp-swiper', {
    modules: [Navigation, Pagination],
    spaceBetween: 15,
    slidesPerView: 'auto',
    direction: 'horizontal',
    loop: false,
    breakpoints: {
      768: {
        spaceBetween: 30
      }
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  })
})

window.addEventListener('DOMContentLoaded', () => {
  const swipers = document.querySelectorAll('.case-swiper')

  swipers.forEach((swiper) => {
    new Swiper(swiper, {
      modules: [Pagination, Navigation],
      spaceBetween: 60,
      slidesPerView: 1.3,
      breakpoints: {
        640: {
          slidesPerView: '2.3'
        },
        1120: {
          slidesPerView: '3.3'
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
  })

  if (window.location.hash === '#demo') {
    initHubspot()
    toggleDemoDrawer(true)
  }

  window.addEventListener('hashchange', () => {
    if (window.location.hash === '#demo') {
      initHubspot()
      toggleDemoDrawer(true)
    }
  })
})

if (document.getElementById('startpage-hero')) {
  const headerWrapper = document.querySelector('.SiteNavigation')
  window.addEventListener('scroll', () => {
    const scrollTop = document.documentElement.scrollTop
    if (scrollTop > 50) {
      headerWrapper?.classList.add('is-scrolled')
    } else {
      headerWrapper?.classList.remove('is-scrolled')
    }
  })
}

const PqiCalculatorWrapper = document.getElementsByClassName('acf-pqi-calculator')
if (PqiCalculatorWrapper.length > 0) {
  import('./PqiCalculator/index.ts')
    .then((module) => {
      Array.from(PqiCalculatorWrapper).forEach((element) => {
        module.default(element as HTMLElement)
      })
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

const NewsBlockWrapper = document.getElementsByClassName('acf-news')
if (NewsBlockWrapper.length > 0) {
  import('./NewsListing/index.ts')
    .then((module) => {
      Array.from(NewsBlockWrapper).forEach((element) => {
        module.default(element as HTMLElement)
      })
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

const NewsArchiveWrapper = document.getElementById('news-archive')
if (NewsArchiveWrapper) {
  import('./NewsArchive/index.ts')
    .then((module) => {
      module.default(NewsArchiveWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

let hasInitiatedHubspot = false

function initHubspot() {
  if (hasInitiatedHubspot) {
    return
  }
  hasInitiatedHubspot = true
  const script = document.createElement('script')
  const scripts = document.getElementsByTagName('script')[0]
  if (!scripts || !scripts.parentNode) {
    console.error('No script tag found. Failed to load Hubspot script.')
    return
  }
  script.src = '//static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
  script.defer = true
  script.async = true
  scripts.parentNode.insertBefore(script, scripts)
}

const Overlay = document.getElementById('Overlay')
const MenuOverlay = document.getElementById('MenuOverlay')
const Body = document.getElementsByTagName('body')[0]

function hideOverlay() {
  Overlay?.addEventListener(
    'transitionend',
    () => {
      Overlay.classList.remove('Show')
    },
    { once: true }
  )
  Overlay?.classList.remove('Fade-In')
}

function showOverlay() {
  Overlay?.classList.add('Show')
  setTimeout(() => Overlay?.classList.add('Fade-In'), 10) // Ensure the transition is visible
}

function hideMenuOverlay() {
  MenuOverlay?.addEventListener(
    'transitionend',
    () => {
      MenuOverlay.classList.remove('Show')
    },
    { once: true }
  )
  MenuOverlay?.classList.remove('Fade-In')
}

function showMenuOverlay() {
  MenuOverlay?.classList.add('Show')
  setTimeout(() => MenuOverlay?.classList.add('Fade-In'), 10) // Ensure the transition is visible
}

const DemoDrawer = document.getElementById('DemoDrawer')
function toggleDemoDrawer(show: boolean) {
  if (DemoDrawer) {
    if (show) {
      DemoDrawer.classList.add('Open')
      showOverlay()
      Body?.classList.add('overflow-hidden')
    } else {
      DemoDrawer.classList.remove('Open')
      hideOverlay()
      // Body?.classList.remove('overflow-hidden')
      if (SecondaryNavigation && !SecondaryNavigation.classList.contains('Open')) {
        Body?.classList.remove('overflow-hidden')
      }
      if (window.location.hash === '#demo') {
        // Remove hash without reloading the page // affecting scroll position:
        history.pushState('', document.title, window.location.pathname + window.location.search)
      }
    }
  }
}

if (DemoDrawer) {
  // on click open drawer
  const openDrawerButton = document.querySelectorAll('.js-open-demo')
  openDrawerButton.forEach((button) => {
    button.addEventListener('click', () => {
      initHubspot()
      if (DemoDrawer.classList.contains('Open')) {
        toggleDemoDrawer(false)
      } else {
        toggleDemoDrawer(true)
      }
    })
  })

  const closeDrawerButton = document.querySelectorAll('.js-close-demo')
  closeDrawerButton.forEach((button) => {
    button.addEventListener('click', () => {
      toggleDemoDrawer(false)
    })
  })
}

const SecondaryNavigation = document.getElementById('SecondaryNavigation')
function toggleSecondaryNavigation(show: boolean) {
  if (SecondaryNavigation) {
    if (show) {
      SecondaryNavigation.classList.add('Open')
      showMenuOverlay()
      Body?.classList.add('overflow-hidden')
    } else {
      SecondaryNavigation.classList.remove('Open')
      hideMenuOverlay()
      Body?.classList.remove('overflow-hidden')
    }
  }
}

if (SecondaryNavigation) {
  const openSecondaryNavigationButton = document.querySelector('#MainMenuToggleButton')
  if (openSecondaryNavigationButton) {
    openSecondaryNavigationButton.addEventListener('click', () => {
      if (SecondaryNavigation.classList.contains('Open')) {
        openSecondaryNavigationButton.classList.remove('is-active')
        toggleSecondaryNavigation(false)
      } else {
        toggleSecondaryNavigation(true)
        openSecondaryNavigationButton.classList.add('is-active')
      }
    })
  }
}

if (SecondaryNavigation) {
  const menuItemsWithChildren = SecondaryNavigation.querySelectorAll('.menu-item-has-children')
  menuItemsWithChildren.forEach((menuItem) => {
    const subMenu = menuItem.querySelector('.sub-menu')
    if (subMenu) {
      menuItem.addEventListener('click', () => {
        if (subMenu.classList.contains('Open')) {
          menuItem.classList.remove('Open')
          subMenu.classList.remove('Open')
        } else {
          menuItem.classList.add('Open')
          subMenu.classList.add('Open')
        }
      })
    }
  })
}

const accordionBtns = document.querySelectorAll('.accordion-btn')
if (accordionBtns.length > 0) {
  accordionBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      const accordion = btn.nextElementSibling
      btn.classList.toggle('is-active')
      if (accordion?.classList.contains('open')) {
        accordion.classList.remove('open')
      } else {
        accordion?.classList.add('open')
      }
    })
  })
}

document.addEventListener('DOMContentLoaded', function () {
  const menuItems = document.querySelectorAll('.menu-item-has-children > a')
  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener('click', function (event) {
      event.preventDefault()
    })
  })
})

import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
const lightbox = document.querySelector('.lightbox')

if (lightbox) {
  const lightbox = new PhotoSwipeLightbox({
    gallery: '.lightbox',
    children: 'a',
    pswpModule: () => import('photoswipe')
  })
  lightbox.init()
}
